import activity from './m-solve.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Solve',
  icon: 'mdi-drawing',
  description: 'Solve problems with your team!',
  status: true,
  setupRequired: true,
  styling: {
    borderColor: '#fcd523'
  },
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-solve',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        boards: []
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
